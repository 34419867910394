import { ButtonColors } from '~constants/etc';

const buttonFill = {
  [ButtonColors.DEFAULT]: {
    background: 'bg-color-button',
    color: 'text-button-default',
  },
  [ButtonColors.PRIMARY]: {
    background: 'bg-color-primary',
    color: 'text-button-primary',
  },
  [ButtonColors.SUCCESS]: {
    background: 'bg-color-success',
    color: 'text-white',
  },
  [ButtonColors.DANGER]: {
    background: 'bg-color-error',
    color: 'text-white',
  },
  [ButtonColors.TRANSPARENT]: {
    background: 'bg-transparent',
    color: 'text-button-default',
  },
  [ButtonColors.EARTH]: {
    background: 'bg-color-brown',
    color: 'text-white',
  },
  [ButtonColors.PURPLE]: {
    background: 'bg-color-evaluated',
    color: 'text-white',
    border: 'border border-color-primary',
  },
};

export default buttonFill;
