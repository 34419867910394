import { ButtonColors } from '~constants/etc';

const buttonGhost = {
  [ButtonColors.DEFAULT]: {
    border: 'border-2 border-white',
    color: 'text-white',
  },
  [ButtonColors.PRIMARY]: {
    border: 'border-2 border-buttonColor-color-base',
    color: 'text-color-primary',
  },
};

export default buttonGhost;
